/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

@font-face {
  font-family: "Sanomat";
  font-weight: bold;
  src: url("/static/fonts/Sanomat/Sanomat-Bold.eot?") format("eot"), url("/static/fonts/Sanomat/Sanomat-Bold.woff") format("woff"), url("/static/fonts/Sanomat/Sanomat-Bold.woff2") format("woff2"), url("/static/fonts/Sanomat/Sanomat-Bold.ttf") format("truetype"), url("/static/fonts/Sanomat/Sanomat-Bold.svg#Sanomat") format("svg"); }

@font-face {
  font-family: "Sanomat";
  font-style: italic;
  font-weight: bold;
  src: url("/static/fonts/Sanomat/Sanomat-Bolditalic.eot?") format("eot"), url("/static/fonts/Sanomat/Sanomat-Bolditalic.woff") format("woff"), url("/static/fonts/Sanomat/Sanomat-Bolditalic.woff2") format("woff2"), url("/static/fonts/Sanomat/Sanomat-Bolditalic.ttf") format("truetype"), url("/static/fonts/Sanomat/Sanomat-Bolditalic.svg#Sanomat") format("svg"); }

@font-face {
  font-family: "Volte";
  font-weight: 600;
  src: url("/static/fonts/Volte/Volte-Semibold.eot?") format("eot"), url("/static/fonts/Volte/Volte-Semibold.woff") format("woff"), url("/static/fonts/Volte/Volte-Semibold.woff2") format("woff2"), url("/static/fonts/Volte/Volte-Semibold.ttf") format("truetype"), url("/static/fonts/Volte/Volte-Semibold.svg#Volte") format("svg"); }

@font-face {
  font-family: "Volte";
  font-weight: 500;
  src: url("/static/fonts/Volte/Volte-Medium.eot?") format("eot"), url("/static/fonts/Volte/Volte-Medium.woff") format("woff"), url("/static/fonts/Volte/Volte-Medium.woff2") format("woff2"), url("/static/fonts/Volte/Volte-Medium.ttf") format("truetype"), url("/static/fonts/Volte/Volte-Medium.svg#Volte") format("svg"); }

@font-face {
  font-family: "Volte";
  font-weight: 400;
  src: url("/static/fonts/Volte/Volte-Regular.eot?") format("eot"), url("/static/fonts/Volte/Volte-Regular.woff") format("woff"), url("/static/fonts/Volte/Volte-Regular.woff2") format("woff2"), url("/static/fonts/Volte/Volte-Regular.ttf") format("truetype"), url("/static/fonts/Volte/Volte-Regular.svg#Volte") format("svg"); }

@font-face {
  font-family: "Volte";
  font-weight: 400;
  src: url("/static/fonts/Volte/Volte-Regular.eot?") format("eot"), url("/static/fonts/Volte/Volte-Regular.woff") format("woff"), url("/static/fonts/Volte/Volte-Regular.woff2") format("woff2"), url("/static/fonts/Volte/Volte-Regular.ttf") format("truetype"), url("/static/fonts/Volte/Volte-Regular.svg#Volte") format("svg"); }

html {
  font-size: 14px;
  color: #58595b; }

html.lock-scroll body {
  overflow: hidden; }

h1,
h2 {
  font-family: "Sanomat";
  font-weight: bold;
  font-size: 2.62em; }

h3,
h4,
h5,
p,
small,
pre,
input,
button,
a {
  font-family: "Volte";
  font-weight: 400; }

h3,
h4,
h5 {
  font-size: 1.3125em;
  font-weight: 600; }

p,
small,
pre,
input,
button,
a {
  font-size: 1em;
  line-height: 25px; }

button,
a {
  cursor: pointer; }

ul,
li {
  font-family: "Volte"; }

header#masthead-main {
  min-height: 75vh;
  background: #f3f3f3;
  align-items: center;
  overflow: hidden;
  padding-top: 35px;
  padding-bottom: 35px; }
  header#masthead-main.--portal-login {
    justify-content: flex-end;
    position: relative;
    overflow: hidden; }
    @media (min-width: 992px) {
      header#masthead-main.--portal-login {
        min-height: unset !important;
        min-width: unset;
        background: #f3f3f3;
        align-items: center;
        overflow: hidden;
        padding-top: 35px;
        height: 45vw;
        padding-bottom: 35px; } }
  header#masthead-main.with-nav {
    margin-top: 80px; }
  header#masthead-main.with-bg {
    overflow: visible; }
    @media (min-width: 768px) {
      header#masthead-main.with-bg--survey-portal {
        background-image: url(/static/images/survey-library-ribbon.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: -500px; } }
  header#masthead-main.background-primary {
    background: #bd3c4b; }
  header#masthead-main.background-gray {
    background: #f3f3f3; }
  header#masthead-main .masthead-main__image--portal-login {
    background-image: url(/static/images/turnkey-login-masthead.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50% 52% 46% 10%;
    height: 53vw;
    width: 75vh;
    width: 55vw;
    transform: translateX(-4vw);
    flex: none;
    position: absolute;
    right: 50%;
    min-width: 80vh;
    min-height: 80vh; }
    @media (min-width: 992px) {
      header#masthead-main .masthead-main__image--portal-login {
        min-height: unset !important;
        min-width: unset;
        background-image: url(/static/images/turnkey-login-masthead.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50% 52% 46% 10%;
        height: 53vw;
        width: 75vh;
        width: 54vw;
        -webkit-transform: translateX(-4vw);
        transform: translateX(-8vw);
        flex: none;
        position: absolute;
        left: 0px;
        max-width: unset; } }
  header#masthead-main .masthead-main__image--survey-library {
    height: 100%;
    background-image: url(/static/images/survey-library-ribbon.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 75vh;
    transform: scale(1.1);
    background-position: left; }

main#content-main .content-main__row {
  padding-top: 50px;
  padding-bottom: 75px; }
  @media (min-width: 768px) {
    main#content-main .content-main__row {
      padding-top: 75px;
      padding-bottom: 75px; } }
  main#content-main .content-main__row .content-section--cap {
    margin: 0; }
    @media (min-width: 992px) {
      main#content-main .content-main__row .content-section--cap {
        max-width: 900px;
        margin: 0 auto; } }
  main#content-main .content-main__row .content-section .content-section__header {
    margin-bottom: 70px;
    max-width: 500px; }
    main#content-main .content-main__row .content-section .content-section__header h2 {
      margin-top: 0; }

footer#footer-main {
  background-color: #58595b;
  background-image: url(/static/images/footer-mask.svg);
  color: white;
  padding-top: 75px;
  padding-bottom: 45px;
  background-size: cover;
  background-position: right bottom; }
  footer#footer-main .footer-row {
    flex-wrap: wrap;
    flex-direction: column; }
    @media (min-width: 768px) {
      footer#footer-main .footer-row {
        flex-wrap: no-wrap;
        flex-direction: row; } }
    footer#footer-main .footer-row__col:first-of-type {
      display: flex;
      flex-direction: row; }
      @media (max-width: 768px) {
        footer#footer-main .footer-row__col:first-of-type {
          flex-direction: column; }
          footer#footer-main .footer-row__col:first-of-type .footer-social {
            margin-left: 0px;
            margin-top: 25px;
            text-align: center; } }
    footer#footer-main .footer-row__col .footer-brand img {
      max-width: 250px;
      filter: brightness(0) invert(1); }
    footer#footer-main .footer-row__col .footer-social {
      margin-left: 45px; }
      footer#footer-main .footer-row__col .footer-social a {
        margin-right: 12px; }
        footer#footer-main .footer-row__col .footer-social a img {
          transform: scale(0.8); }
    footer#footer-main .footer-row__col .footer-text h5 {
      font-size: 0.9375em;
      font-weight: 400; }
    footer#footer-main .footer-row__col .footer-text--bold h5 {
      font-weight: 600; }
    footer#footer-main .footer-row__col .footer-text--terms > a {
      text-decoration: none;
      color: white; }
    footer#footer-main .footer-row--top {
      margin-bottom: 35px; }
      @media (min-width: 768px) {
        footer#footer-main .footer-row--top {
          margin-bottom: 55px; } }
    footer#footer-main .footer-row--bottom {
      margin-top: 35px; }
      @media (min-width: 768px) {
        footer#footer-main .footer-row--bottom {
          margin-top: 40px; } }

nav#nav-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 1; }
  nav#nav-main .nav-main__container .nav-main__row {
    padding-left: 20px;
    padding-right: 20px;
    min-height: 80px; }
    @media (min-width: 992px) {
      nav#nav-main .nav-main__container .nav-main__row {
        padding-left: 35px;
        padding-right: 35px; } }
    nav#nav-main .nav-main__container .nav-main__row .nav-main__brand.menu-expanded img {
      filter: brightness(0%) invert(1); }
    nav#nav-main .nav-main__container .nav-main__row .nav-main__brand img {
      filter: brightness(100%) invert(0);
      max-width: 100%;
      will-change: filter;
      transition: 0.3s filter ease-in-out;
      position: relative;
      z-index: 1; }
    @media (max-width: 980px) {
      nav#nav-main .nav-main__container .nav-main__row .nav-main__items {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center; }
        nav#nav-main .nav-main__container .nav-main__row .nav-main__items[data-expand="false"] {
          opacity: 0;
          pointer-events: none; }
        nav#nav-main .nav-main__container .nav-main__row .nav-main__items[data-expand="true"] {
          transition: 0.2s opacity ease-in-out;
          opacity: 1;
          pointer-events: all; }
        nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul {
          flex-direction: column; }
          nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li {
            font-size: 22px;
            margin-top: 20px; }
            nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li:after, nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li:before {
              display: none;
              opacity: 0; }
            nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li a {
              color: white; }
          nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__sub {
            position: relative;
            opacity: 1;
            background: transparent;
            padding-top: 10px; }
            nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__sub li {
              border: none; }
              nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__sub li a {
                color: #bfbfbf; }
        nav#nav-main .nav-main__container .nav-main__row .nav-main__items .dropdown-close {
          position: absolute;
          top: 30px;
          right: 20px;
          width: 32px;
          height: 32px;
          outline: 0;
          border: 0;
          background: transparent; } }
    nav#nav-main .nav-main__container .nav-main__row .nav-main__items ul {
      list-style-type: none;
      padding: 0; }
    nav#nav-main .nav-main__container .nav-main__row .nav-main__items a {
      text-decoration: none;
      color: #58595b; }
    nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul {
      list-style-type: none;
      display: flex;
      justify-content: flex-end; }
      nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li {
        position: relative;
        padding: 17px 12.5px 12.5px;
        position: relative; }
        nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li a {
          display: inline-block; }
          @media (min-width: 992px) {
            nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li a:hover {
              color: #bd3c4b; } }
        nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li.dropdown {
          padding-right: 17.5px; }
          nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li.dropdown:after {
            content: "";
            border: solid #58595b;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 2px;
            transform: rotate(45deg) translate(-50%, 0%);
            -webkit-transform: rotate(45deg) translate(-100%, 0%);
            right: 0px;
            top: 50%;
            position: absolute; }
        nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li:hover.dropdown .nav-main__sub {
          opacity: 1;
          pointer-events: all; }
        @media (min-width: 768px) {
          nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li--offtop {
            padding-bottom: 0;
            position: absolute;
            right: 12.5px;
            top: -10px; }
            nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__ul .nav-main__li--offtop a {
              font-size: 13px;
              color: #ee434a; } }
    nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__sub {
      position: absolute;
      left: 0px;
      width: 100%;
      background: white;
      padding-top: 30px;
      opacity: 0;
      transition: 0.2s opacity ease-in-out;
      will-change: opacity;
      pointer-events: none; }
      nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__sub .nav-main__li {
        border-bottom: 1px solid #bfbfbf;
        margin: 0px 5px; }
        nav#nav-main .nav-main__container .nav-main__row .nav-main__items .nav-main__sub .nav-main__li:last-of-type {
          border-bottom: none; }

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(47, 47, 47, 0.6);
  opacity: 0;
  will-change: opacity;
  transition: 0.2s opacity ease-in-out;
  pointer-events: none;
  z-index: 1; }
  .modal-container.show {
    opacity: 1;
    pointer-events: all; }
  .modal-container__modal {
    width: 350px;
    background: #f3f3f3;
    padding: 55px 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.2);
    display: none; }
    @media (max-width: 768px) {
      .modal-container__modal {
        max-height: 450px;
        overflow: scroll;
        -webkit-overflow-scrolling: touch; }
        .modal-container__modal.full-height {
          max-height: none; } }
    .modal-container__modal.modal-active {
      display: block; }
  .modal-container__close {
    text-align: right; }
    .modal-container__close button {
      outline: none;
      border: none;
      background: none;
      position: relative;
      color: #585858;
      height: 20px;
      width: 20px;
      display: inline-block;
      position: absolute;
      top: 20px;
      right: 20px; }
      .modal-container__close button:before, .modal-container__close button:after {
        background: #58595b; }
  .modal-container__image {
    text-align: center; }
  .modal-container__header {
    text-transform: uppercase;
    font-size: 0.9375em; }
  .modal-container__form input {
    width: 100%;
    padding: 0px 10px;
    height: 45px;
    margin-bottom: 15px;
    font-size: 1em; }
  .modal-container__form .g-recaptcha {
    transform: scale(0.925);
    transform-origin: 0 0;
    margin-bottom: 25px; }
  .modal-container__form button[type="submit"] {
    display: block;
    margin: 0 auto; }

.button-x:before, .button-x:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 25px;
  width: 2px;
  background-color: white;
  top: -10%; }

.button-x:before {
  transform: rotate(45deg); }

.button-x:after {
  transform: rotate(-45deg); }

.privacy-policy-section a {
  color: #bd3c4b; }

.portal-login {
  max-width: 400px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .portal-login {
      margin: 0; } }
  .portal-login__brand {
    margin-bottom: 35px; }
  .portal-login__container > h4 {
    font-size: 0.9375em;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 25px; }
  .portal-login__container form#login-form .login-form__cta {
    text-align: center;
    margin-top: 25px; }
    @media (min-width: 992px) {
      .portal-login__container form#login-form .login-form__cta {
        margin-top: 0px;
        text-align: left; } }
  .portal-login__container form#login-form .login-form__inputs > input {
    height: 55px;
    margin-bottom: 15px;
    border: 1px solid #bfbfbf;
    padding: 10px;
    border-radius: 5px; }
    .portal-login__container form#login-form .login-form__inputs > input::placeholder {
      color: #dad9d6;
      font-size: 1em;
      font-weight: 400; }
  .portal-login__container form#login-form .login-form__inputs .login-form__morelinks {
    text-align: center; }
    @media (min-width: 992px) {
      .portal-login__container form#login-form .login-form__inputs .login-form__morelinks {
        text-align: right; } }
    .portal-login__container form#login-form .login-form__inputs .login-form__morelinks > button.login-form__recover {
      outline: none;
      border: none;
      background: transparent;
      text-align: right;
      font-size: 0.9375em;
      margin-top: 5px;
      display: inline-block; }
    .portal-login__container form#login-form .login-form__inputs .login-form__morelinks > button.login-form__support {
      outline: none;
      border: none;
      background: transparent;
      text-align: right;
      font-size: 0.9375em;
      margin-top: 5px;
      display: inline-block; }

.services {
  justify-content: space-between; }
  .services__col {
    margin-bottom: 35px; }
    @media (min-width: 992px) {
      .services__col {
        max-width: 400px; } }
    @media (min-width: 768px) {
      .services__col {
        margin-bottom: 0px; } }
    .services__col:last-of-type {
      margin-bottom: 0px; }
  .services__image {
    width: 100%;
    height: 250px;
    background: #f3f3f3; }
    .services__image img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      padding: 50px; }

.service-boxes__box {
  margin-bottom: 25px;
  width: 100%;
  max-width: 350px;
  text-align: center; }
  .service-boxes__box:last-of-type {
    margin-bottom: 0; }

.service-boxes__row {
  background-color: white;
  padding: 35px 0px; }
  .service-boxes__row:first-of-type {
    padding: 45px 0px;
    background-color: #ee434a; }

.service-boxes__subheader {
  margin-bottom: 25px; }
  .service-boxes__subheader h5 {
    margin: 0; }

.service-boxes__cta a {
  display: inline-block; }

.user-features {
  margin-bottom: -35px; }
  .user-features__item {
    flex-basis: calc(25%);
    text-align: center;
    padding: 10px;
    margin-bottom: 35px; }
  .user-features__img img {
    max-width: 75px; }
  .user-features__button a {
    color: #58595b;
    font-weight: 500; }

.inner-content {
  background: #f3f3f3;
  padding: 25px; }
  .inner-content__wrapper {
    max-width: 100%; }
    @media (min-width: 992px) {
      .inner-content__wrapper {
        max-width: 50%; } }

.survey-filter {
  flex-direction: column; }

.survey-items__item {
  padding: 45px 0px; }
  .survey-items__item:first-of-type {
    padding-top: 0px; }

.survey-items__boxes.basis-50 .survey-items__box {
  height: auto; }
  @media (min-width: 768px) {
    .survey-items__boxes.basis-50 .survey-items__box {
      position: relative;
      height: 350px; } }
  @media (max-width: 1091px) and (min-width: 768px) {
    .survey-items__boxes.basis-50 .survey-items__box {
      height: 555px; } }
  @media (min-width: 992px) {
    .survey-items__boxes.basis-50 .survey-items__box {
      flex-basis: calc(50% - 35px);
      max-width: calc(50% - 35px); }
      .survey-items__boxes.basis-50 .survey-items__box:nth-of-type(2n) {
        margin-right: 0px; }
      .survey-items__boxes.basis-50 .survey-items__box .survey-items__boxcta {
        flex-direction: row;
        bottom: 25px; }
        .survey-items__boxes.basis-50 .survey-items__box .survey-items__boxcta button {
          margin-right: 20px;
          margin-bottom: 0px; }
          .survey-items__boxes.basis-50 .survey-items__box .survey-items__boxcta button:last-of-type {
            margin-right: 0px; } }
  @media (max-width: 768px) {
    .survey-items__boxes.basis-50 .survey-items__box .survey-items__boxcta {
      position: relative;
      left: 0;
      bottom: 0; } }
  .survey-items__boxes.basis-50 .survey-items__box button {
    margin-bottom: 20px; }

.survey-items__box {
  flex: 1;
  position: relative;
  flex-basis: 100%;
  padding: 25px;
  background: #f3f3f3;
  margin-bottom: 35px; }
  @media (min-width: 992px) {
    .survey-items__box {
      flex-basis: calc(33% - 35px);
      max-width: calc(33% - 35px);
      margin-right: 35px; }
      .survey-items__box:nth-of-type(3n) {
        margin-right: 0px; } }
  @media (min-width: 1200px) {
    .survey-items__box {
      height: 555px; } }
  @media (min-width: 768px) and (max-width: 1200px) {
    .survey-items__box {
      height: 610px; } }
  @media (max-width: 992px) and (min-width: 768px) {
    .survey-items__box {
      flex-basis: calc(50% - 35px);
      max-width: calc(50% - 35px);
      margin-right: 35px; }
      .survey-items__box:nth-of-type(2n) {
        margin-right: 0px; }
      .survey-items__box:last-of-type {
        margin-right: 0px; } }

.survey-items__boxheader h5 {
  margin-bottom: 35px; }

.survey-items__boxcta {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 35px; }
  @media (min-width: 768px) {
    .survey-items__boxcta {
      position: absolute;
      bottom: 15px;
      left: 25px; } }
  .survey-items__boxcta button {
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .survey-items__boxcta button {
        max-width: 175px; } }
    @media (max-width: 768px) {
      .survey-items__boxcta button {
        min-width: 100%; } }
  .survey-items__boxcta a {
    color: white; }

.survey-items .seperator {
  margin-left: -15px;
  margin-right: -15px; }

@media (max-width: 768px) {
  .hidden-sm-down {
    display: none; } }

@media (min-width: 768px) {
  .hidden-sm-up {
    display: none; } }

@media (max-width: 992px) {
  .hidden-md-down {
    display: none; } }

@media (min-width: 992px) {
  .hidden-md-up {
    display: none; } }

.hidden {
  display: none; }

.button-main {
  padding: 10px 45px;
  border-radius: 25px;
  outline: none;
  border: none;
  font-size: 0.9375em;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  transition: 0.25s background ease-in-out, 0.25s color ease-in-out, 0.25s border-color ease-in-out; }
  .button-main a {
    text-decoration: none; }
    .button-main a span.small {
      text-transform: lowercase; }
  .button-main--primary {
    background: #ee434a;
    color: white; }
    .button-main--primary:hover {
      background: #bd3c4b; }
  .button-main--ghost {
    background: transparent;
    color: #58595b;
    border: 1px solid #707070;
    white-space: nowrap; }
    .button-main--ghost:hover {
      background: #ee434a;
      border-color: #ee434a;
      color: white; }

.button-caret {
  position: relative;
  display: inline-block;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  width: auto;
  padding-right: 75px;
  margin-bottom: 10px;
  color: #58595b;
  background: transparent; }
  .button-caret:after {
    position: absolute;
    content: "";
    display: block;
    bottom: -10px;
    width: 100%;
    background: #58595b;
    height: 2px; }
  .button-caret:before {
    position: absolute;
    content: "";
    display: block;
    right: 0px;
    top: 50%;
    border: solid #58595b;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transition: 0.3s transform ease-in-out;
    transform: rotate(-45deg) translateY(-50%) translateX(0);
    -webkit-transform: rotate(-45deg) translateY(-50%); }
  .button-caret:hover:before {
    transform: rotate(-45deg) translateY(calc(-50% + 5px)) translateX(5px); }

span.seperator {
  width: 100%;
  height: 1px;
  background: #dad9d6;
  display: block; }

span.horizontal-bar {
  width: 0px;
  height: auto;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  position: relative; }
  span.horizontal-bar:after {
    content: "";
    display: block;
    background: white;
    width: 1px;
    height: 100%;
    max-height: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0; }

.hamburger-button {
  padding: 5px;
  outline: none;
  border: none;
  background: transparent;
  display: block;
  height: 34px;
  width: 34px; }

.hamburger-icon {
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  background: #58595b;
  pointer-events: none; }
  .hamburger-icon:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    height: 2px;
    background: #58595b;
    width: 100%; }
  .hamburger-icon:before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    height: 2px;
    background: #58595b;
    width: 100%; }

.color-main-2 {
  color: #ee434a; }

.select-caret {
  position: relative; }
  .select-caret button {
    background: transparent;
    outline: none;
    border: none; }
  .select-caret button.selected-option {
    position: relative;
    display: inline-block;
    outline: none;
    border: none;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    width: 100%;
    text-align: left;
    padding-left: 0;
    margin-bottom: 10px;
    color: #58595b;
    background: transparent; }
    @media (min-width: 992px) {
      .select-caret button.selected-option {
        width: 50%; } }
    .select-caret button.selected-option:after {
      position: absolute;
      content: "";
      display: block;
      bottom: -10px;
      width: 100%;
      background: #58595b;
      height: 2px; }
    .select-caret button.selected-option:before {
      position: absolute;
      content: "";
      display: block;
      right: 10px;
      top: 50%;
      border: solid #58595b;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transition: 0.3s all ease-in-out;
      transform: rotate(45deg) translateY(-50%) translateX(0);
      -webkit-transform: rotate(45deg) translateY(-50%);
      transform-origin: center; }
  .select-caret .select-options {
    padding: 15px 5px;
    max-width: 100%;
    opacity: 0;
    pointer-events: none;
    background: white;
    will-change: opacity;
    transition: 0.25s opacity ease-in-out;
    position: absolute;
    top: 40px;
    z-index: 999;
    width: 100%; }
    @media (min-width: 992px) {
      .select-caret .select-options {
        max-width: 50%; } }
    .select-caret .select-options__option {
      padding: 5px; }
      .select-caret .select-options__option.selected button {
        color: #ee434a; }
      .select-caret .select-options__option button {
        font-weight: 400;
        color: #58595b; }
  .select-caret.show button.selected-option:after {
    background: #ee434a; }
  .select-caret.show button.selected-option:before {
    border-color: #ee434a;
    transform: rotate(-135deg) translateY(-50%) translateX(0); }
  .select-caret.show .select-options {
    opacity: 1;
    pointer-events: all; }
